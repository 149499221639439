

.form {
	@include media-breakpoint-down(lg) {
		margin-bottom: 70px; } }

.form-group {
	margin-bottom: 2rem; }
// 	position: relative
// 	.zmdi
// 		color: $text-grey
// 		+rem(20)
// 		position: absolute
// 		right: 0.75rem
// 		top: 50%
// 		margin-top: -10px


.form-control {
	text-align: center;
	border-radius: 20px;
	border-color: transparent;
	height: calc(2.5rem + 2px);
	border: none; }


// .form-control:focus
// 	border-color: transparent
// 	box-shadow: none



label {
	width: 100%;
	text-align: center; }
// 	display: inline-block
// 	vertical-align: top
// 	margin-right: 15px
// 	color: #fff
// 	[type='file'],
// 	[type='checkbox'],
// 	[type='radio']
// 		margin-bottom: 0

// input,
// textarea,
// select,
// label,
// [type='text'],
// [type='password'],
// [type='date'],
// [type='datetime'],
// [type='datetime-local'],
// [type='month'],
// [type='week'],
// [type='email'],
// [type='number'],
// [type='search'],
// [type='tel'],
// [type='time'],
// [type='url'],
// [type='color'],
// textarea
// 	+fcn
// 	+rem(14)
// 	min-height: 45px


// label
// 	min-height: auto


// textarea
// 	height: auto
// 	max-height: 300px
// 	min-height: 170px
// 	max-width: 100%
// 	overflow: hidden
// 	width: 100%


// .checkbox-group
// 	margin-bottom: 10px

// 	[type="checkbox"],
// 	label
// 		margin-bottom: 0px


// .form-error
// 	+fcn


.form-control::-webkit-input-placeholder {
	color: #9a9592; }

.form-control:-moz-placeholder {
	color: #9a9592; }

.form-control::-moz-placeholder {
	color: #9a9592; }

.form-control:-ms-input-placeholder {
	color: #9a9592; }

// .invalid-feedback
// 	text-align: left





// select:not([multiple])
// 	-webkit-appearance: none
// 	-moz-appearance: none
// 	background-position: 98% 50%
// 	background-repeat: no-repeat
// 	background-size: 15px
// 	background-image: url(../img/tpl/form-select-arrow.svg)






// .success-form
// 	background-color: $warning
// 	max-width: 400px
// 	padding: 30px
// 	font-weight: 300
// 	+rem(16)
// 	color: #fff !important
// 	text-align: center
// 	border-radius: 10px
// 	box-shadow: 7px 13px 32px 0px rgba(0, 0, 0, 0.25)
// 	img
// 		display: block
// 		margin: 0 auto
// 		width: 100%
// 		max-width: 180px




// form#language
// 	z-index: 1010
// 	.btn, .nav-link
// 		+rem(15)
// 		img
// 			margin-right: 8px
// 			margin-top: -3px








// @media (max-width: 767px)
// 	form#language .btn img, form#language .nav-link img
// 		margin-right: 0


