
html {
	font-size: $font-size-base + px; }

body {
	@include rem(16);
	background-color: $secondary;
	color: $body-color;
	&.no-scroll {
		margin: 0;
		height: 100%;
		overflow: hidden; } }

.main {
	position: relative;
	overflow: hidden; }

.ff-open-sans {
	font-family: 'Open Sans', sans-serif !important; }

.ff-playfair-display {
	font-family: 'Playfair Display', sans-serif !important; }

.ff-caveat {
	font-family: 'Caveat', cursive !important; }


.box-shadow-1 {
	box-shadow: 0px 0px 20px rgba(0,0,0,0.1) !important; }



.p-static {
	position: static !important; }

.p-relative {
	position: relative !important; }


// FONT-WEIGHT
// thin
.fw-100 {
	font-weight: 100 !important; }

// extra-light
.fw-200 {
	font-weight: 200 !important; }

// light
.fw-300 {
	font-weight: 300 !important; }

// regular
.fw-400 {
	font-weight: 400 !important; }

// medium
.fw-500 {
	font-weight: 500 !important; }

// semi-bold
.fw-600 {
	font-weight: 600 !important; }

// bold
.fw-700 {
	font-weight: 700 !important; }

// extra-bold
.fw-800 {
	font-weight: 800 !important; }

// black
.fw-900 {
	font-weight: 900 !important; }

// END FONT-WEIGHT




// HEADERS
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	// font-weight: 700
	// font-family: 'Montserrat', sans-serif

	a {
		color: inherit;
		@include hf {
			color: inherit; } } }

h1,
.h1 {
	margin-bottom: 0.4em;
	@include media-breakpoint-down(sm) {
		@include rem(32); }
	&.bigger {
		@include rem(72); } }

h2,
.h2 {
	margin-bottom: 0.4em;
	@include media-breakpoint-down(sm) {
		@include rem(26); } }

h3,
.h3 {
	margin-bottom: 0.4em; }

h4,
.h4 {
	margin-bottom: 0.4em;
	&.bigger {
		@include rem(20); } }

h5,
.h5 {
	margin-bottom: 0.4em; }

h6,
.h6 {
	margin-bottom: 0.4em; }

// END HEADERS



// TITLE STYLES
.title-shadow-black {
	text-shadow: 0px 0px 1px rgba(0,0,0,0.4); }

.title-shadow-white {
	text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.55); }

.text-grey {
	color: $text-grey !important; }

.title-line-height {
	line-height: 36px !important; }

.line-height-20 {
	line-height: 20px !important; }

.line-height-22 {
	line-height: 22px !important; }

.line-height-26 {
	line-height: 26px !important; }

.line-height-30 {
	line-height: 30px !important; }

.line-height-40 {
	line-height: 40px !important; }

// END TITLE STYLES




.bg-grey {
	background-color: #f1efec !important; }

.bg-brown {
	background-color: #40362f !important; }




::selection {
	background-color: $secondary;
	color: #fff; }

p,
address,
ol,
ul,
dl,
blockquote {
	margin-bottom: 1em; }


address {
	font-style: normal; }


.tabs {
	@include fs;
	a {
		@include fc {
			outline: 0; } } }

.badge,
.label {
	margin-bottom: 4px;

	&.success,
	&.primary,
	&.warning,
	&.alert {
		color: #fff; } }


hr {
	border: none;
	background-color: rgba(255,255,255,.1);
	width: 100%;
	height: 1px;
	margin-top: 1.5rem;
	margin-bottom: 1.5rem; }



.box-shadow {
	box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.32); }












// @media screen and (max-width: 991px)

// 	h1.bigger, .h1.bigger
// 		+rem(52)

// 	h1, .h1
// 		+rem(32)
// 	h2, .h2
// 		+rem(28)
// 	h3, .h3
// 		+rem(24)



// @media screen and (max-width: 767px)

// 	h1.bigger, .h1.bigger
// 		+rem(44)

// 	h1, .h1
// 		+rem(30)

// 	h2, .h2
// 		+rem(30)
// 		&.bigger
// 			+rem(36)




// @media screen and (max-width: 475px)
// 	h1.bigger, .h1.bigger
// 		+rem(40)




// @media screen and (max-width: 320px)
// 	h1.bigger, .h1.bigger
// 		+rem(35)

@media (max-width: 1439px) {
	h1.bigger {
		@include rem(65); } }

@media (max-width: 1339px) {
	h1.bigger {
		@include rem(55); } }


h1.bigger {
	@include media-breakpoint-down(xs) {
		@include rem(40); } }


@media (min-width: 1200px) {
	.container {
		max-width: 1450px;
		&.bigger {
			max-width: 1820px; }
		&.smaller {
			max-width: 1250px; } } }






// Extra small
// <576px	Small
// ≥576px	Medium
// ≥768px	Large
// ≥992px	Extra large
// ≥1200px

// breakpoint-only
// @include media-breakpoint-only(xs)
// @include media-breakpoint-only(sm)
// @include media-breakpoint-only(md)
// @include media-breakpoint-only(lg)
// @include media-breakpoint-only(xl)

// max-width
// @include media-breakpoint-down(xs)
// @include media-breakpoint-down(sm)
// @include media-breakpoint-down(md)
// @include media-breakpoint-down(lg)

// min-width
// @include media-breakpoint-up(sm)
// @include media-breakpoint-up(md)
// @include media-breakpoint-up(lg)
// @include media-breakpoint-up(xl)









