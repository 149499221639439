
.section-contact-info {
	padding: 70px 0 0px;
	background-color: #40362f;
	@include media-breakpoint-down(sm) {
		padding: 40px 0 0; } }

.logo-footer {
	display: inline-block;
	margin-bottom: 50px;
	img {
		width: 100%;
		max-width: 342px;
		display: block; }
	@include media-breakpoint-down(xs) {
		margin-bottom: 30px; } }

.contact-list {
	padding: 0;
	margin: 0;
	margin-bottom: 60px;
	@include rem(15);
	font-weight: 200;
	li {
		list-style: none;
		color: #cdc5ba;
		margin-bottom: 30px; }
	a {
		display: inline-block;
		color: #cdc5ba; }
	&.openning-time {
		li {
			margin-bottom: 18px; }
		span {
			min-width: 110px;
			display: inline-block; } }

	@include media-breakpoint-down(xs) {
		margin-bottom: 40px; } }


.social-list {
	margin: 0;
	padding: 0;
	text-align: center;
	font-size: 0;
	li {
		list-style: none;
		margin: 0 7px;
		padding: 0;
		display: inline-block; }
	a {
		display: inline-block;
		color: #d1cabe;
		@include trans;
		@include rem(32);
		padding: 0px 5px;
		&:hover {
			text-decoration: none;
			color: #a47661; } } }


.nva-logo {
	text-align: center;
	img {
		width: 100%;
		max-width: 312px;
		@include media-breakpoint-down(xs) {
			max-width: 212px; } } }






// @media screen and (max-width: 991px)
