

// .footer
.footer {
	padding: 0px 0px;
	background-color: #40362f;
	.container {
		@include media-breakpoint-down(md) {
			width: 100%;
			max-width: 100%; } }

	hr {
		margin-top: 0;
		background-color: #544339; } }

.copyright {
	color: #cdc5ba;
	margin-bottom: 24px;
	@include rem(12);
	@include media-breakpoint-down(xs) {
		@include rem(11); } }



.footer-link {
	color: #cdc5ba;
	@include rem(12);
	margin-bottom: 30px;
	display: inline-block;
	@include media-breakpoint-down(xs) {
		@include rem(11); } }



.footer-list-privacy {
	li {
		@include media-breakpoint-down(xs) {
			text-align: center;
			display: block; } } }


