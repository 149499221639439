.section-content {
	position: relative;

	.leaf-2 img {
		max-width: 130px; } }


.collapse-link {
	display: inline-block;
	padding: 5px;
	padding-top: 10px;
	position: relative;
	font-family: 'Playfair Display', sans-serif;
	font-weight: 600;
	&::before {
		content: '\f2f9';
		font-size: 20px;
		font-family: Material-Design-Iconic-Font;
		position: absolute;
		top: -7px;
		left: 50%;
		transform: translateX(-50%) rotate(180deg);
		@include trans; }
	&.collapsed {
		&::before {
			transform: translateX(-50%) rotate(0deg); } } }



.section-content-img {
	min-height: 420px;
	width: 100%;
	max-width: 100%;
	border-radius: 6px;
	background-image: url(../img/section-content-img-1.jpg);
	background-size: cover;
	background-position: center;
	box-shadow: 0px 5px 1px 0px $success;
	@include media-breakpoint-down(lg) {
		min-height: 300px; }
	@include media-breakpoint-down(md) {
		min-height: 200px; }
	&--box-shadow {
		border-radius: 6px;
		width: 100%;
		max-width: 100%;
		box-shadow: 0px 0px 20px rgba(0,0,0,0.2); }
	&.section-content-img--2 {
		background-image: url(../img/section-content-img-2.jpg); }
	&.section-content-img--3 {
		background-image: url(../img/section-content-img-3.jpg); } }





