.product-card {
	background-color: #fff;
	border-radius: 6px;
	box-shadow: 12px 12px 35px rgba(134, 115, 90, 0.3);
	max-width: 390px;
	width: 100%;
	margin: 0 auto;
	margin-bottom: 50px;
	@include media-breakpoint-down(sm) {
		margin-bottom: 70px; }
	&-image {
		img {
			border-radius: 6px;
			width: 100%;
			max-width: 390px; } }
	&-body {
		padding: 25px 15px;
		text-align: center; }
	&-title {
		font-family: 'Playfair Display', sans-serif;
		font-weight: 700;
		margin-bottom: 20px; }
	&-time {
		@include rem(18);
		color: #bbaca6;
		margin-bottom: 10px; }
	&-price {
		@include rem(45);
		font-weight: 700;
		font-family: 'Playfair Display', sans-serif;
		color: $success;
		margin-bottom: 10px; }
	a {
		color: inherit;
		&:hover {
			text-decoration: none; } }
	&-btn.btn {
		margin-bottom: -55px;
		color: #fff; } }

.section-product-card {
	padding-bottom: 80px;
	@include media-breakpoint-down(sm) {
		padding-bottom: 0px; } }





