

.btn {
	@include trans;
	@include rem(18);
	font-weight: 400;
	padding: 0.75rem 1.5rem;
	border-radius: 5rem;
	min-width: 216px;
	&.btn-full-width {
		width: 100%; } }


.btn:hover,
.btn:focus,
.btn:visited,
.btn:active {
	outline: none;
 }	// box-shadow: 3px 6px 12px 0px rgba(0, 0, 0, 0.35)

button:hover {
	cursor: pointer; }

.btn-secondary {
	box-shadow: 0px 3px 1px 0px darken( $secondary, 30% ); }

.btn-success {
	box-shadow: 0px 3px 1px 0px darken( $success, 20% ); }

.btn-warning {
	box-shadow: 0px 3px 1px 0px darken( $warning, 30% ); }

.btn-shadow:not([disabled]):not(.disabled):active {
	box-shadow: 7px 13px 32px 0px rgba(0, 0, 0, 0.35); }

.btn-radius {
	border-radius: 50px; }

.btn-sm, .btn-group-sm > .btn {
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
	min-width: auto; }

.btn-icon .zmdi {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-transition-timing-function: ease-out;
	transition-timing-function: ease-out; }

.btn-icon:hover .zmdi,
.btn-icon:focus .zmdi,
.btn-icon:active .zmdi {
	-webkit-animation-name: hvr-icon-pulse;
	animation-name: hvr-icon-pulse;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;


 } // Icon Pulse
@-webkit-keyframes hvr-icon-pulse {
	25% {
		-webkit-transform: scale(1.2);
		transform: scale(1.2); }

	75% {
		-webkit-transform: scale(0.9);
		transform: scale(0.9); } }


@keyframes hvr-icon-pulse {
	25% {
		-webkit-transform: scale(1.2);
		transform: scale(1.2); }

	75% {
		-webkit-transform: scale(0.9);
		transform: scale(0.9); } }


.hvr-icon-pulse {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	.hvr-icon {
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
		-webkit-transition-timing-function: ease-out;
		transition-timing-function: ease-out; }
	&:hover .hvr-icon, &:focus .hvr-icon, &:active .hvr-icon {
		-webkit-animation-name: hvr-icon-pulse;
		animation-name: hvr-icon-pulse;
		-webkit-animation-duration: 1s;
		animation-duration: 1s;
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite; } }



.gotop-btn {
	position: fixed;
	right: 0px;
	bottom: 50px;
	z-index: -2;
	padding: 4px 20px;
	font-size: 2rem;
	background-color: $warning;
	opacity: 0;
	display: inline-block;
	border-radius: 50%;
	transition: all .2s ease-in-out;
	box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.3);
	transform: scale(0.9);
	color: #fff;
	border: 1px solid #b29486;
	&::before {
		border-radius: 50%;
		content: "";
		display: inline-block;
		border: 1px solid #b29486;
		width: 58px;
		height: 58px;
		position: absolute;
		left: 1px;
		top: 0; } }


.gotop-btn:hover {
	box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.3);
	background-color: #fff;
	transform: scale(0.93); }


.gotop-btn.visible {
	right: 20px;
	opacity: 1;
	z-index: 995; }


.btn-warning {
	color: #fff; }







.menu {
	-webkit-transition: all 300ms ease-in-out;
	transition: all 300ms ease-in-out;
	.menu_title {
		-webkit-transition: all 300ms ease-in-out;
		transition: all 300ms ease-in-out; }
	ul li a {
		-webkit-transition: all 300ms ease-in-out;
		transition: all 300ms ease-in-out;
		&:after, &:before {
			-webkit-transition: all 300ms ease-in-out;
			transition: all 300ms ease-in-out; } }
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	border-top: 1px solid #fff;
	margin: 0;
	padding: 60px 0;
	.menu_title {
		color: #ccc;
		font-size: 1.5em;
		font-weight: 300;
		margin: 0 0 20px; }
	&:hover .menu_title {
		color: #0af;
		text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1); }
	ul {
		display: block;
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			display: inline-block;
			a {
				color: #555;
				display: block;
				line-height: 50px;
				padding: 0 30px;
				text-decoration: none; } } }

	&.effect-10 ul li a {
		border: 1px solid rgba(0, 0, 0, 0);
		margin: 0 10px;
		&.bdr_btm {
			border-bottom: 1px solid rgba(0, 170, 255, 0.8); }
		&.bdr_lft {
			border-left: 1px solid rgba(0, 204, 68, 0.8); }
		&.bdr_rgt {
			border-right: 1px solid rgba(255, 0, 170, 0.8); }
		&.bdr_top {
			border-top: 1px solid rgba(170, 0, 255, 0.8); }
		&:hover {
			border: 1px solid rgba(0, 0, 0, 0.2);
			border-radius: 30px; } } }



.btn-custom {
	color: $warning;
	display: inline-block;
	line-height: 50px;
	padding: 0 30px;
	text-decoration: none;
	@include trans;
	border: 1px solid rgba(0, 0, 0, 0);
	&.bdr_btm {
		border-bottom: 1px solid $warning; }
	&.bdr_lft {
		border-left: 1px solid $warning; }
	&.bdr_rgt {
		border-right: 1px solid $warning; }
	&.bdr_top {
		border-top: 1px solid $warning; }
	&:hover {
		border: 1px solid $dark;
		color: #fff;
		background-color: $dark;
		border-radius: 30px;
		text-decoration: none; } }


.btn-phone {
	padding-right: 60px;
	position: relative;
	z-index: 1;
	span {
		background-color: #fff;
		@include rem(22);
		color: $warning;
		border-radius: 50%;
		padding: 3px;
		width: 40px;
		height: 40px;
		position: absolute;
		right: 3px;
		top: 2px;
		display: inline-block;
		cursor: pointer;
		box-shadow: 0 0 0 #fff;
		animation: pulse 2s infinite;
		&:hover {
			animation: none; } } }









.btn-slide {
	position: relative;
	display: inline-block;
	text-align: center;
	overflow: hidden !important;
	-webkit-transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
	z-index: 10;
	&::before {
		content: '';
		width: 0%;
		height: 100%;
		display: block;
		background-color: $primary;
		position: absolute;
		-ms-transform: skewX(-20deg);
		-webkit-transform: skewX(-20deg);
		transform: skewX(-20deg);
		left: -10%;
		opacity: 1;
		top: 0;
		z-index: -12;
		-moz-transition: all 0.7s cubic-bezier(0.77, 0, 0.175, 1);
		-o-transition: all 0.7s cubic-bezier(0.77, 0, 0.175, 1);
		-webkit-transition: all 0.7s cubic-bezier(0.77, 0, 0.175, 1);
		transition: all 0.7s cubic-bezier(0.77, 0, 0.175, 1);
		box-shadow: 2px 0px 14px rgba(0, 0, 0, 0.6); }
	&::after {
		content: '';
		width: 0%;
		height: 100%;
		display: block;
		background-color: $warning;
		position: absolute;
		-ms-transform: skewX(-20deg);
		-webkit-transform: skewX(-20deg);
		transform: skewX(-20deg);
		left: -10%;
		opacity: 0;
		top: 0;
		z-index: -15;
		-webkit-transition: all 0.94s cubic-bezier(0.2, 0.95, 0.57, 0.99);
		-moz-transition: all 0.4s cubic-bezier(0.2, 0.95, 0.57, 0.99);
		-o-transition: all 0.4s cubic-bezier(0.2, 0.95, 0.57, 0.99);
		transition: all 0.4s cubic-bezier(0.2, 0.95, 0.57, 0.99);
		box-shadow: 2px 0px 14px rgba(0, 0, 0, 0.6); }
	&:hover::before {
		opacity: 1;
		width: 116%; } }

.btn-slide:hover::before {
	opacity: 1;
	width: 116%; }

.btn-slide:hover::after, .btn-slide:hover::after {
	opacity: 1;
	width: 120%; }



























