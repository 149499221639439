.section-about {
	position: relative;
	background-size: cover;
	background-position: center bottom;
	&--light {
		background-color: #f1efec;
		background-image: url(../img/section-about-bg-light.jpg); }
	&--brown {
		background-color: #40362f;
		background-image: url(../img/section-about-bg-brown.jpg); }
	&-text {
		max-width: 610px;
		line-height: 26px;
		z-index: 4; }
	&-banner {
		position: relative;
		min-height: 380px;
		border-radius: 6px;
		background-image: url(../img/about-banner-img.jpg);
		background-size: cover;
		background-position: center;
		box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
		@include media-breakpoint-down(md) {
			margin-bottom: 40px; }
		&--acupunctuur {
			background-image: url(../img/about-banner-img-2.jpg);
			min-height: 480px;
			@include media-breakpoint-down(md) {
				min-height: 280px; } }
		&--author {
			background-image: url(../img/section-about-author-photo.jpg);
			background-position: center top;
			min-height: 520px; }
		&--text {
			border-radius: 6px;
			padding: 48px;
			background-color: #fff;
			box-shadow: 0px 5px 1px 0px darken( $secondary, 30% );
			display: inline-block;
			max-width: 330px;
			line-height: 25px;
			font-weight: 600;
			color: #5c5652;
			@include media-breakpoint-down(md) {
				padding: 30px; }
			@include media-breakpoint-down(xs) {
				padding: 15px; }
			&.banner-green {
				background-color: $success;
				box-shadow: 0px 5px 1px 0px darken( $success, 20% );
				color: #fff;
				max-width: 400px;
				padding: 30px 40px;
				@include media-breakpoint-down(lg) {
					padding: 20px; }
				@include media-breakpoint-down(xs) {
					padding: 10px;
					padding-bottom: 20px; } } } } }


.banner-shadow {
	box-shadow: 0px 0px 20px rgba(0,0,0,0.2);
	border-radius: 6px;
	margin-top: -60px;
	margin-left: -100px;
	display: inline-block;
	@include media-breakpoint-down(md) {
		margin-top: -50px;
		margin-left: -30px; }
	@include media-breakpoint-down(sm) {
		margin-top: -30px; }
	@include media-breakpoint-down(xs) {
		margin-left: -10px; } }

.section-about-banner--author .banner-shadow {
	margin-top: 0;
	margin-left: 0;
	position: absolute;
	bottom: -90px;
	left: -90px;
	@include media-breakpoint-down(lg) {
		left: auto;
		right: -20px; }
	@include media-breakpoint-down(sm) {
		bottom: -40px; }
	@include media-breakpoint-down(xs) {
		right: -10px; } }

.signature-img {
	margin-top: -15px;
	img {
		width: 100%;
		max-width: 150px; }
	@include media-breakpoint-down(xs) {
		margin-top: 0; } }


.leaf-1 {
	position: absolute;
	right: 0;
	top: -140px;
	z-index: 3;
	@include media-breakpoint-down(md) {
		top: -118px; }
	@include media-breakpoint-down(sm) {
		top: -80px; }
	img {
		width: 100%;
		max-width: 240px;
		@include media-breakpoint-down(md) {
			max-width: 140px; }
		@include media-breakpoint-down(sm) {
			max-width: 100px; } }

	&.bottom {
		top: auto;
		bottom: 0px; } }


.leaf-2 {
	position: absolute;
	bottom: -60px;
	left: 0;
	z-index: 3;
	img {
		width: 100%;
		max-width: 240px;
		@include media-breakpoint-down(lg) {
			max-width: 190px; }
		@include media-breakpoint-down(sm) {
			max-width: 90px; } }
	@include media-breakpoint-down(lg) {
		bottom: -70px; }
	@include media-breakpoint-down(sm) {
		bottom: -40px; } }


.leaf-3 {
	position: absolute;
	right: 0;
	bottom: -200px;
	z-index: 3;
	@include media-breakpoint-down(lg) {
		bottom: -100px; }
	@include media-breakpoint-down(sm) {
		bottom: -50px; }
	img {
		width: 100%;
		max-width: 270px;
		@include media-breakpoint-down(lg) {
			max-width: 170px; }
		@include media-breakpoint-down(sm) {
			max-width: 100px; } } }

.leaf-4 {
	position: absolute;
	bottom: -180px;
	left: 0;
	z-index: 3;
	@include media-breakpoint-down(lg) {
		bottom: -100px; }
	@include media-breakpoint-down(sm) {
		bottom: -50px; }
	img {
		width: 100%;
		max-width: 340px;
		@include media-breakpoint-down(lg) {
			max-width: 240px; }
		@include media-breakpoint-down(sm) {
			max-width: 140px; } } }




