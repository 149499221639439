


.list-inline-item:not(:last-child) {
	margin-right: 1.5rem; }

.list-inline-item-footer:not(:last-child) {
	margin-right: 0.5rem; }














