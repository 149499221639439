.section-tabs {
	position: relative; }

.tab-content {
	z-index: 5;
	position: relative;
	@include media-breakpoint-down(lg) {
		margin-bottom: 50px; }
	@include media-breakpoint-down(sm) {
		padding-left: 10px;
		border-left: 4px solid $success; } }


.nav-tabs {
	border: none;
	margin-bottom: 35px;
	z-index: 5;
	position: relative;
	@include media-breakpoint-down(sm) {
		margin-bottom: 10px; }

	.nav-link {
		color: #bbaca6;
		@include rem(18);
		font-family: 'Playfair Display', sans-serif;
		font-weight: 700;
		border: none;
		padding-right: 0;
		padding-left: 0;
		position: relative;
		&:hover {
			color: $success; }
		&::before {
			content: '\f2f9';
			font-size: 20px;
			font-family: Material-Design-Iconic-Font;
			position: absolute;
			top: 50%;
			left: -17px;
			transform: translateY(-50%) rotate(180deg);
			@include trans;
			display: none;
			@include media-breakpoint-down(sm) {
				display: inline-block; } }
		&.show {
			&::before {
				transform: translateY(-50%) rotate(0deg); } } }

	.nav-item {
		margin-right: 20px;
		margin-left: 20px;
		&:first-child {
			margin-left: 0; }
		&:last-child {
			margin-right: 0; }
		@include media-breakpoint-down(sm) {
			width: 100%;
			margin: 0;
			margin-bottom: 10px;
			padding-left: 20px; } } }

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
	color: $success;
	background-color: transparent;
	border-color: $success;
	border-bottom: $success 2px solid;
	@include media-breakpoint-down(sm) {
		border: none; } }

.tabs-content-link {
	display: inline-block;
	&:hover {
		text-decoration: none; } }


.tabs-image {
	img {
		width: 100%;
		max-width: 600px;
		border-radius: 6px;
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2); } }













