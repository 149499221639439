.section {
	padding: 50px 0; }

.section-bigger {
	padding: 130px 0; }

.section-title {
	text-align: center;
	font-weight: 700; }

.section-subtitle {
	@include rem(30);
	font-family: 'Caveat', cursive;
	color: $warning;
	font-style: italic;
	position: relative;
	padding-left: 95px;
	padding-right: 100px;
	text-align: center;
	display: inline-block;
	&::before,
	&::after {
		display: inline-block;
		content: "";
		position: absolute;
		top: 55%;
		transform: translateY(-50%);
		width: 0;
		height: 0;
		border-top: 2px solid transparent;
		border-bottom: 2px solid transparent;
		border-radius: 6px; }

	&::before {
		border-right: 80px solid $success;
		left: 0;
		@include media-breakpoint-down(xs) {
			border-right: 40px solid $success;
			transform: rotate(-100deg);
			left: -15px; } }

	&::after {
		border-left: 80px solid $success;
		right: 0;
		@include media-breakpoint-down(xs) {
			border-left: 40px solid $success;
			transform: rotate(100deg);
			right: -20px; } }


	@include media-breakpoint-down(xs) {
		padding-left: 10px;
		padding-right: 10px;
		display: block; } }





@media (max-width: 767px) {

	.section-bigger {
		padding: 50px 0; } }










