
.secondary-nav {
	padding: 20px 0;
	@include media-breakpoint-down(lg) {
		.container {
			width: 100%;
			max-width: 100%; } }
	@include media-breakpoint-down(sm) {
		padding: 10px 15px; } }


.navbar-light .secondary-nav .nav-link {
	color: #5c5652;
	@include media-breakpoint-down(lg) {
		color: #fff; } }
















