.section-home {
	background-image: url(../img/home-bg.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-color: #fff;
	color: #fff;
	text-align: center;
	// @include media-breakpoint-down(lg)

	&-secondary {
		background-image: url(../img/acupunctuur-home-bg.jpg);
		&--tarieven {
			background-image: url(../img/tarieven-home-bg.jpg); }
		&--contact {
			background-image: url(../img/contact-home-bg.jpg); }
		.section-home-blink {
			padding: 250px 0px 150px;
			background-color: rgba(0, 0, 0, 0.5); } }




	&-blink {
		padding: 320px 0px 200px;
		background-color: rgba(0,0,0,0.3);
		@include media-breakpoint-down(lg) {
			.container {
				width: 100%;
				max-width: 100%; } }
		@include media-breakpoint-down(xs) {
			padding: 320px 0px 50px; } }

	&-title {
		position: relative;
		padding-left: 140px;
		padding-right: 140px;
		display: inline-block;
		&::before,
		&::after {
			display: inline-block;
			content: "";
			position: absolute;
			top: 55%;
			transform: translateY(-50%);
			width: 0;
			height: 0;
			border-top: 2px solid transparent;
			border-bottom: 2px solid transparent;
			border-radius: 6px; }

		&::before {
			border-right: 120px solid #fff;
			left: 0;
			@include media-breakpoint-down(md) {
				border-right: 90px solid #fff; }
			@include media-breakpoint-down(sm) {
				transform: rotate(-100deg);
				left: -33px; }
			@include media-breakpoint-down(xs) {
				left: -43px; } }

		&::after {
			border-left: 120px solid #fff;
			right: 0;
			@include media-breakpoint-down(md) {
				border-left: 90px solid #fff; }
			@include media-breakpoint-down(sm) {
				transform: rotate(100deg);
				right: -33px; }
			@include media-breakpoint-down(xs) {
				right: -43px; } }


		@include media-breakpoint-down(md) {
			padding-left: 100px;
			padding-right: 100px; }

		@include media-breakpoint-down(sm) {
			padding-left: 30px;
			padding-right: 30px; }

		@include media-breakpoint-down(xs) {
			padding-left: 10px;
			padding-right: 10px; } } }




