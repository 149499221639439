
// custom variables and mixins
@import "variables";
@import "mixins";

// bootstrap
@import "bootstrap/scss/bootstrap.scss";

// custom global
@import "global";

// custom components
@import "components/buttons.sass";
@import "components/flip-card.sass";
@import "components/footer.sass";
@import "components/forms.sass";
@import "components/header.sass";
@import "components/layout.sass";
@import "components/list.sass";
@import "components/modal.sass";
@import "components/navbar.sass";
@import "components/owl-carousel.sass";
@import "components/product-card.sass";
@import "components/section-about.sass";
@import "components/section-call-action.sass";
@import "components/section-contact-info.sass";
@import "components/section-contact.sass";
@import "components/section-content.sass";
@import "components/section-home.sass";
@import "components/section-tabs.sass";
@import "components/tabs.sass";
@import "components/type.sass";
@import "components/typography.sass";


