
.flip-card {
	position: relative;
	width: 250px;
	height: 190px;
	min-height: 190px;
	transform-style: preserve-3d;
	transform: rotatey(0deg) translatex(0px) translatey(0px);
	transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
	margin: 5px;
	cursor: pointer;
	text-align: center;
	border-radius: 6px;
	background-color: #fff;
	display: inline-block;
	@include rem(14);

	&:hover {
		transform: rotatey(40deg) translatex(0px) translatey(0px);
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.14); }

	&.open {
		width: 250px;
		height: auto;
		transform: rotatey(-180deg) translatex(0px) translatey(0px);
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.14);

		& .back-facing {
			opacity: 1; } }

	&-list {
		margin: 0;
		margin-bottom: 10px;
		padding: 0;
		text-align: left;
		li {
			list-style: none;
			padding: 0;
			margin-bottom: 12px;
			padding-left: 13px;
			position: relative;
			line-height: 20px; }
		i {
			position: absolute;
			left: 0;
			top: 5px;
			font-size: 70%; } }

	&-icon {
		padding: 15px;
		border-radius: 50%;
		border: 1px solid #dedcdc;
		position: relative;
		display: inline-block;
		width: 115px;
		height: 115px;
		margin-bottom: 15px;
		&::before {
			content: "";
			border-radius: 50%;
			display: inline-block;
			width: 117px;
			height: 117px;
			background-color: transparent;
			border: 1px solid #dedcdc;
			position: absolute;
			top: 2px;
			right: -5px; }
		img {
			width: 100%;
			max-width: 67px;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%); } }

	.front-facing {
		transform: rotateY(0deg) translateZ(2px);
		padding: 10px;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: #fff;
		border: 2px white solid;
		border-radius: 6px;

		.flip-card-title {
			margin: 0;
			text-align: center;
			font-weight: 600; } }

	.back-facing {
		@include trans;
		transform: rotateY(180deg) translateZ(0px);
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		height: 100%;
		background-color: #fff;
		border: 2px white solid;
		border-radius: 6px;
		padding: 20px;
		text-align: center;
		overflow: hidden;
		opacity: 0; } }



@media (max-width: 1439px) {
	.flip-card {
		width: 240px; } }

@media (max-width: 1339px) {
	.flip-card {
		width: 210px; }

	.flip-card.open {
		width: 210px; }

	.flip-card .back-facing {
		padding: 10px; } }


.flip-card {
	@include media-breakpoint-down(xs) {
		width: 170px; } }

.flip-card.open {
	@include media-breakpoint-down(xs) {
		width: 170px; } }

.flip-card .back-facing {
	@include media-breakpoint-down(xs) {
		padding: 5px; } }



@media (max-width: 389px) {
	.flip-card {
		width: 135px;
		@include rem(12); }

	.flip-card.open {
		width: 170px; }

	.flip-card-list li {
		line-height: 16px;
		margin-bottom: 0; } }


@media (max-width: 320px) {
	.flip-card.open {
		width: 130px; } }




