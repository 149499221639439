
.section-call-action {
	padding: 150px 0 100px;
	background-color: $success;
	background-image: url(../img/call-action-green-bg.jpg);
	background-size: cover;
	background-position: center bottom;
	@include media-breakpoint-down(lg) {
		padding: 100px 0 100px; }



	@include media-breakpoint-down(sm) {
		.container {
			width: 100%;
			max-width: 100%; }

		br {
			display: none; } }



	&.section-call-action--green {
		.btn-success {
			color: $success;
			background-color: #fff;
			&:hover,
			&:focus {
				color: #fff;
				background-color: #627639;
				border-color: #5b6e34; } } }

	&.section-call-action--btn {
		background-image: url(../img/call-action-btn-bg.jpg);
		background-position: center bottom; } }
