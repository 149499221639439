
// .owl-nav
// 	display: none

// .owl-theme .owl-dots
// 	margin-bottom: 0px
// 	.owl-dot
// 		span
// 			+trans
// 			width: 7px
// 			height: 7px
// 			background-color: #999999
// 		&.active
// 			span
// 				background-color: $warning

// .owl-theme.owl-image .owl-dots
// 	padding-top: 10px
// 	margin-bottom: 0

// .item
// 	text-align: center
// 	padding: 0 3px




