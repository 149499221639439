
.header {
	position: relative;

	&_content {
		box-shadow 0px 0px 26.73px 0.27px rgba(29, 29, 29, 0.15) {}
		position: absolute;
		left: 0;
		right: 0;
		top: 50px;

		.container {
			@include media-breakpoint-down(lg) {
				max-width: 100%; } } } }

.mobile-nav-bg {
	background-color: rgba(0,0,0,0.6);
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 998;
	display: none;
	transition: all .5s ease-in-out; }

.mobile-nav-bg.show {
	display: block; }

.mobile-phone {
	margin-right: 70px;
	@include rem(30);
 }	// color: #fff

.nav-item--phone {
	display: none; }

.custom-nav {
	min-height: 0px;
	top: 0px;
	right: 0;
	left: 0;
	z-index: 1007;
	background-color: #fff;
	border-radius: 6px;
	@include trans;
	padding-top: 0;
	padding-bottom: 0;

	.btn {
		@include rem(16); }

	.navbar-toggler {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 15px;
		transition: all .5s ease-in-out;
		width: auto;
		height: auto;
		padding: 0;
		&:focus {
			outline: none; } }
	.navbar-toggler-icon {
		background-image: none;
		width: 28px;
		height: 20px;
		margin: 3px;
		.top-line,
		.middle-line,
		.bottom-line {
			display: inline-block;
			box-shadow: 0px 0px 1px rgba(0,0,0,.5);
			background-color: #fff;
			height: 3px;
			border-radius: 1px;
			position: absolute;
			left: 0;
			right: 0;
			@include trans; }
		.top-line {
			background-color: $primary;
			top: 0; }
		.middle-line {
			background-color: $primary;
			top: 50%;
			transform: translateY(-50%);
			left: auto;
			right: 0;
			width: 34px; }
		.bottom-line {
			background-color: $primary;
			bottom: 0;
			left: auto;
			right: 0;
			width: 34px; } }

	.navbar-toggler.active {
		color: #fff;
		right: 265px;
		.navbar-toggler-icon {
			.top-line {
				top: 50%;
				margin-top: -1px;
				transform: rotate(-45deg);
				background-color: #fff;
				width: 100%; }
			.middle-line {
				opacity: 0;
				background-color: #fff;
				width: 100%; }
			.bottom-line {
				bottom: auto;
				top: 50%;
				margin-top: -1px;
				transform: rotate(45deg);
				background-color: #fff;
				width: 100%; } } }

	.primary-nav {
		.nav-link {
			font-weight: 600;
			@include rem(16);
			color: #5c5652;
			text-transform: uppercase;
			white-space: nowrap;
			@include trans;
			padding-right: 0;
			padding-left: 0;
			border: none;
			border-bottom: 2px solid transparent;
 } }			// text-shadow: 0px 0px 20px rgba(0,0,0,0.3)

	.nav-item {
		padding: 20px 15px; } }

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
	color: $success;
	// font-weight: 700
	border-bottom: 2px solid $success; }

.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
	color: $success;
	// font-weight: 700
	border-bottom: 2px solid $success; }

.navbar-brand {
	padding: 10px 0;
	line-height: 0;
	margin: 0;
	position: relative;
	z-index: 3;
	img {
		width: 100%; }
	.logo {
		max-width: 346px;
		display: inline-block;
		@include media-breakpoint-down(xs) {
			max-width: 165px; } }
	.logo-smaller {
		max-width: 290px;
		display: none;
		@include media-breakpoint-down(xs) {
			max-width: 165px; } } }



.navbar-toggler {
	font-size: 16px; }


@keyframes nav-down {
	0% {
		top: -100px; }

	100% {
		top: 0px; } }


.header_content.smaller-menu {
	@include trans;
	box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.16);

	.navbar-nav {
		padding: 0;
		background-color: transparent; }

	.custom-nav {
		-webkit-animation: nav-down 0.6s 1;
		-moz-animation:    nav-down 0.6s 1;
		-o-animation:      nav-down 0.6s 1;
		animation:         nav-down 0.6s 1;
		position: fixed;
		left: 5px;
		right: 5px;
		top: 5px;
		@include trans;
		box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.16);
		border-top: 0px solid transparent;
		border-bottom: 0px solid transparent;


		.navbar-toggler {
			color: #fff;
			font-size: 16px; }
		.navbar-toggler.active {
			color: #fff;
			.top-line,
			.middle-line,
			.bottom-line {
				background-color: #fff; } } }

	.nav-item.bigger-screen-padding {
		padding-left: 0; }

	.nav-item--phone {
		display: block;
		@include media-breakpoint-down(lg) {
			display: none; } }

	.navbar-brand {
		.logo {
			display: none; }
		.logo-smaller {
			display: inline-block; } }

	.navbar-phone-btn {
		background-color: #fff;
		color: #5c5652 !important;
		margin-top: 0px;
		margin-bottom: 0px;
		text-align: center;
		border-bottom-right-radius: 6px;
		border-bottom-left-radius: 6px;
		&:hover {
			color: $success !important; }
		i {
			color: $success; }
		@include media-breakpoint-down(lg) {
			background-color: transparent;
			color: #fff !important; } } }



.navbar-toggler {
	cursor: pointer;
	padding: 5px 10px; }

.navbar-app-link {
	padding: 24px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	.app-link {
		margin-right: 0;
		margin-bottom: 12px; } }

.dropdown-item:hover, .dropdown-item:focus {
	color: $success;
	background-color: lighten( $warning, 45% );
	background-color: transparent;
	@include trans;
	padding-left: 2rem;
	padding-right: 1rem; }

.dropdown-menu {
	border: 2px solid lighten( $warning, 30% );
	@include media-breakpoint-down(lg) {
		display: inline-block;
		background-color: transparent;
		border: none; } }

.dropdown-item {
	@include media-breakpoint-down(lg) {
		color: #fff;
		padding: 0.25rem 1rem; } }

.navbar-nav.secondary-nav {
	flex-direction: column;
	padding: 0;
	.nav-item {
		padding-top: 0;
		padding-bottom: 0;
		padding-right: 0;
		border: 0; }
	.nav-link:hover,
	.nav-link:focus {
		border: 0; } }


.navbar-btn {
	text-align: center;
	@include rem(17);
	i {
		@include rem(26);
		position: relative;
		bottom: -3px;
		@include media-breakpoint-down(lg) {
			bottom: -1px; } } }

.navbar-phone-btn {
	font-weight: 700;
	color: #fff !important;
	// margin-top: -5px
	// margin-bottom: 5px
	padding-bottom: 11px;
	text-align: center;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
	box-shadow: none;
	letter-spacing: 1px;
	@include trans;
	i {
		@include rem(26);
		position: relative;
		bottom: -3px;
		@include media-breakpoint-down(lg) {
			color: $success;
			bottom: -2px; } }

	&.btn-success {
		@include media-breakpoint-down(lg) {
			background-color: transparent;
			border-color: transparent; } }
	// span
	// 	display: inline-block
	// 	@include media-breakpoint-down(lg)
 }	// 		display: none



.dropdown:hover > .dropdown-menu {
	display: block; }



@media (max-width: 1439px) {
	.navbar-brand .logo {
		max-width: 290px; }

	.navbar-phone-btn {
		padding-bottom: 8px;
		// margin-top: 0
 } }		// margin-bottom: 0




@media (max-width: 1339px) {
	.navbar-brand .logo {
		max-width: 190px; }
	.navbar-brand .logo-smaller {
		max-width: 200px; }

	.navbar-phone-btn {
		margin-top: -4px;
		i {
			@include rem(22); } }

	.navbar-btn {
		@include rem(12);
		i {
			@include rem(20); } } }


.navbar-btn {
	@include media-breakpoint-down(lg) {
		@include rem(16); } }


@media (min-width: 1200px) {
	.header_content.smaller-menu {
		.custom-nav {
			.navbar-collapse {
				position: static; } } } }




@media screen and (max-width: 1199px) {

	.header {
		.navbar-nav {
			background-color: transparent; } }

	.custom-nav {

		.container {
			width: 100%;
			max-width: 100%;
			padding-left: 15px;
			padding-right: 15px; }

		.navbar-toggler {
			z-index: 999;
			border: none;
			margin-left: 20px;
			color: #000;
			padding-right: 0;
			padding-left: 0; }

		.navbar-collapse {
			background-color: #34363A;
			position: fixed;
			right: 0;
			top: 0;
			height: 100vh;
			width: 270px;
			padding-top: 30px;
			padding-bottom: 50px;
			padding-left: 0;
			transition: all .5s ease-in-out;
			z-index: 1003;
			overflow-y: scroll; }

		.mobile-nav {
			right: -260px;
			opacity: 0;
			margin: 0; }

		.mobile-nav.show {
			right: 0;
			opacity: 1; }

		.navbar-nav {
			.nav-item {
				margin-bottom: 10px;
				padding: 0 24px; }
			.nav-link {
				color: #fff;
				margin: 0;
				padding: 0.25rem 0;
				display: inline-block;
				border-bottom: 2px solid transparent; } } }


	.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
		color: $primary;
		border-bottom: 2px solid $primary; }

	.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
		color: $primary;
		border-bottom: 2px solid $primary; } }



@media screen and (max-width: 767px) {
	.navbar-app-link {
		position: relative; } }





@media screen and (max-width: 549px) {
	.navbar-brand {
		display: block;
		width: 100%;
		text-align: center; }

	.custom-nav .navbar-toggler {
		top: auto;
		bottom: 0;
		right: 10px; } }



@media screen and (max-width: 349px) {
	.mobile-afspraak-maken {
		@include rem(14); }

	.custom-nav {
		padding-left: 10px;
		padding-right: 10px; } }


