




// Colors

$secondary: #9d9180;
$success: #779045;
$primary: $success;
// $danger: #C63F4F
// $danger: #D82B40
// $warning: #ff9600
$warning: #a47661;
// $info: $cyan
// $light: #daeaed
$light: #fff;
$dark: #000;
$grey: #605856;

$text-grey: #ccc;
$text-muted: #999999;



// main text color
$main: #605856;
$body-color: #605856;

$body-bg: #fff;


// Fonts
$font-size-base: 16;
$font-size-base-custom: 16;
$font-size-base-mobile: 16;

// Font Families
$font-family-base: 'Open Sans', sans-serif;
$font-main: $font-family-base;
$font-secondary: $font-family-base;
$font-condensed: $font-family-base;

@mixin fm {
	font-family: $font-main; }

@mixin fs {
	font-family: $font-secondary; }

@mixin fcn {
	font-family: $font-condensed; }

@mixin fcn2 {
	font-family: $font-condensed; }



// headings
$h1-font-size: 42px;
$h2-font-size: 30px;
$h3-font-size: 24px;
$h4-font-size: 18px;
$h5-font-size: 16px;
$h6-font-size: 14px;

// $h1-font-size-custom: 42
// $h2-font-size-custom: 30
// $h3-font-size-custom: 24
// $h4-font-size-custom: 18
// $h5-font-size-custom: 14
// $h6-font-size-custom: 12


// $home-title-box-shadow: #153aa9


// Animations Time
$transition-time: 0.2s;


// $btn-transition: background-color .3s ease-in-out, border-color .3s ease-in-out, box-shadow .3s ease-in-out

// $input-btn-padding-y: 0.5rem
// $input-btn-padding-x: 1.2rem


