


// .nav-tabs
// 	border: none
// 	margin-bottom: 20px
// 	justify-content: center
// 	.nav-link
// 		color: $dark
// 		font-weight: 700
// 		border-radius: 0
// 		border-color: transparent
// 		padding-left: 0
// 		padding-right: 0
// 		margin-right: 30px
// 		margin-left: 30px
// 		+trans
// 		&:hover
// 			border-color: transparent
// 			color: $warning
// 		&:last-child
// 			margin-right: 0
// 		&:first-child
// 			margin-left: 0

// .nav-tabs .nav-link.active,
// .nav-tabs .nav-item.show .nav-link
// 	border-color: transparent
// 	border-bottom-color: $warning
// 	color: $warning
// 	@include media-breakpoint-down(sm)
// 		background-color: $secondary


// .mortgage-tabs
// 	@include media-breakpoint-down(sm)
// 		flex-direction: column
// 		margin-right: -15px
// 		margin-bottom: 0px
// 		background-color: #fff

// 	.nav-link
// 		text-transform: uppercase
// 		@include media-breakpoint-down(md)
// 			+rem(12)
// 		@include media-breakpoint-down(sm)
// 			+rem(13)
// 			margin-right: 0
// 			margin-left: 0
// 			text-align: left
// 			margin-bottom: 5px
// 			margin-top: 5px
// 			padding-left: 2rem
// 			padding-right: 1rem
// 			border: none
// 			background-color: #fff

// 			&:first-child
// 				margin-top: 0



// .mortgage-tabs-container
// 	@include media-breakpoint-down(sm)
// 		background-color: $secondary
// 		margin-left: -15px
// 		padding-left: 15px



// .solution-tabs
// 	justify-content: space-around
// 	.nav-link
// 		text-align: center
// 		background-color: transparent
// 		@include media-breakpoint-down(sm)
// 			margin-right: 0
// 			margin-left: 0
// 		span
// 			display: block

// 		&:hover
// 			& .services-card--img img
// 				animation: icon-go 0.6s linear

// 	.services-card--img
// 		background-color: #fff
// 		display: inline-block
// 		position: static
// 		margin: 0
// 		margin-bottom: 1rem
// 		.white-icon
// 			display: none

// 		@include media-breakpoint-down(sm)
// 			margin: 0
// 			width: 60px
// 			height: 60px
// 			padding-top: 10px
// 		img
// 			width: 100%
// 			max-width: 60px
// 			@include media-breakpoint-down(sm)
// 				max-width: 30px


// 	.nav-link.active,
// 	.nav-item.show .nav-link
// 		background-color: transparent
// 		border-color: transparent
// 		.services-card--img
// 			background-color: $warning
// 		.orang-icon
// 			display: none
// 		.white-icon
// 			display: inline-block


// .solution-tab-content
// 	background-color: #fff
// 	padding: 40px
// 	border-radius: 10px
// 	box-shadow: -1px 8px 12px 0px rgba(189, 194, 198, 0.34)
// 	@include media-breakpoint-down(sm)
// 		padding: 20px 15px














