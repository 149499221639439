.contact-block {}
.contact-page-list {
	width: 100%;
	margin-bottom: 60px;
	&-title {
		margin-bottom: 0px;
		color: $warning;
		@include rem(30);
		font-family: 'Caveat', cursive; }
	li {
		margin-bottom: 25px;
		list-style: none;
		position: relative;
		color: $main;
		a {
			color: inherit; }
		&.open-time {
			margin-bottom: 40px;
			span {
				min-width: 120px;
				display: inline-block; } } }
	&-icon {
		position: absolute;
		left: -30px;
		top: -3px;
		img {
			width: 100%;
			max-width: 21px; } } }


.contact-page-nva-logo {
	padding-top: 10px;
	img {
		width: 100%;
		max-width: 316px; } }










// .section-contact
// 	padding: 0
// 	.container-fluid
// 		padding-right: 0
// 		padding-left: 0

// .photo-subsection
// 	background-image: url(../img/contact-img.jpg)
// 	background-repeat: no-repeat
// 	background-size: cover
// 	background-position: center bottom
// 	@include media-breakpoint-down(lg)
// 		background-position: center top

// 	&-blink
// 		padding: 100px 15px
// 		background-color: rgba(0,0,0,0.4)
// 		@include media-breakpoint-down(lg)
// 			padding: 150px 15px 30px




// .contact-box
// 	border: 8px solid rgba(255,255,255,0.5)
// 	background-color: rgba($dark, 0.6)
// 	text-align: center
// 	border-radius: 50%
// 	width: 300px
// 	height: 300px
// 	margin-left: auto
// 	margin-right: 100px
// 	padding: 40px 15px
// 	padding-left: 25px
// 	@include media-breakpoint-down(lg)
// 		margin: 0



// .form-subsection
// 	background-color: $warning
// 	padding: 50px 60px
// 	color: #fff
// 	position: relative
// 	z-index: 2
// 	overflow: hidden
// 	@include media-breakpoint-down(sm)
// 		padding: 30px 15px
// 	&::after,
// 	&::before
// 		content: ""
// 		display: inline-block
// 		// background-color: $warning-2
// 		width: 400px
// 		height: 400px
// 		border-radius: 50%
// 		position: absolute
// 		z-index: -1
// 		+trans-2
// 	&::before
// 		width: 200px
// 		height: 200px
// 		left: -130px
// 		top: -130px
// 	&::after
// 		right: -140px
// 		bottom: -150px
// 	&:hover
// 		&::before
// 			width: 320px
// 			height: 320px
// 		&::after
// 			width: 340px
// 			height: 340px
















