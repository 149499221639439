
.modal-content {
	border-radius: 6px;
	background-color: #ecf0f5; }

.modal-header {
	border-color: transparent;
	padding: 5px 15px; }
.modal-footer {
	border-color: transparent; }

.modal-header .close {
	@include rem(21); }



@media (min-width: 576px) {
	.modal-dialog {
		max-width: 800px; } }

